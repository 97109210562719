/** @jsx jsx */
import * as React from "react"
import { Fragment } from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import ReactJWPlayer from "react-jw-player"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/button"
import tw, { styled } from "twin.macro"
import { jsx, css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact } from "@fortawesome/free-brands-svg-icons"
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { Formik, useFormik } from "formik"
import { PieChart } from "react-minimal-pie-chart"
import PromoBlocks from "../../components/standardPromoBlock"
import IndexHero from "../../components/indexHero"
import IndexSwiper from "../../components/indexSwiper"

import * as Yup from "yup"
import ReactPlayer from "react-player"
import IndexTestimonialSwiper from "../../components/indexTestimonialSwiper"
import IndexSolutions from "../../components/indexSolutions"
import IndexProducts from "../../components/indexProducts"
import IndexCaseStudies from "../../components/indexCaseStudies"
import IndexBlogs from "../../components/indexBlogs"
import IndexLeadership from "../../components/indexLeadership"
import IndexWhitepaper from "../../components/indexWhitepaper"
import NewEvalForm from "../../components/newEvalForm"
import IndexLogos from "../../components/indexLogos"
import IndexIndustryRecognition from "../../components/indexIndustryRecognition"
import IndexInvestors from "../../components/indexInvestors"
import IndexFreeTrial from "../../components/indexFreeTrial"

const customArchitectureStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .75)",
    zIndex: "999",
  },
  content: {
    background: "none",
    // backgroundColor: '#000',
    padding: "0",
    // marginTop: "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
}

Modal.setAppElement("body")

const ArchitectureImage = () => {
  return (
    <div className={"flex items-center justify-center border border-white rounded-5xl bg-white h-[90vh] w-[90vw]"}>
      <StaticImage
        src={"../../images/ecommerceReferenceArchitectureBig.png"}
        className={""}
        imgClassName={"w-full"}
        quality={95}
        objectFit={"scale-down"}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt={"CDN Reserve reference architecture"}
      />
    </div>
  )
}

function ZycadaArchitectureModal() {
  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#FFF"
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className={"p-6 hover:border hover:border-zycadaDarkPurple border border-white rounded-5xl"}>
      <a onClick={openModal}>
        <StaticImage
          src="../../images/ecommerceReferenceArchitecture.png"
          className={""}
          imgClassName={""}
          quality={95}
          objectFit={"scale-down"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="eCommerce reference architecture "
          styles={{}}
          loading={"eager"}
          placeholder={"none"}
        />
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={customArchitectureStyles}
        contentLabel="Zycada video modal"
        overlayClassName={""}
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}></h2>
        <ArchitectureImage></ArchitectureImage>
      </Modal>
    </div>
  )
}

const SolutionsEcommerce = () => (
  <Layout>
    <SEO title="" />

    <div className="">
      <div className={"pt-24"}>
        <div className="row pt-24 ">
          <div className="">
            <div className="container flex flex-col justify-center">
              <div className="row w-full">
                <div className=" text-center sm:text-left">
                  <h1 className=" display-4 fs-5 text-zycadaDarkPurple ">
                    Zycada for eCommerce 
                  </h1>

                  <div className={" sm:text-left"}>
                    <p className={"text-base"}>
                      eCommerce revenue in the retail industry has been growing
                      rapidly YoY, and COVID-19 has accelerated that trend even
                      further. The need to deliver superior online experiences
                      has become even more critical to drive business success.
                      Shoppers expect all eCommerce sites to be as fast as
                      technology giants like Google and Facebook, who have
                      seemingly infinite resources and engineering capabilities.
                    </p>

                    <p className={"text-base"}>
                      The bulk of the eCommerce business logic that impacts
                      customer engagement and search engine rankings is
                      non-cacheable dynamic content. Yet many merchants are
                      still trying to leverage CDNs to speed up their static as
                      well as dynamic content. CDNs are great, but were
                      primarily built to manage static asset delivery using
                      caching technology. So, why not give your team the right
                      tool for the job: A platform that can speed up dynamic
                      content as well as CDNs can speed up static content.
                      Welcome to Zycada's programmable intelligent edge
                      platform.
                    </p>

                    <p className={"text-base"}>
                      Zycada has created the world's first Cloud Service
                      Accelerator, which uses Real User Behavioral Intelligence
                      (RUBI) to understand how shoppers interact with eCommerce
                      sites and uses that information to speed up the sites in
                      real-time. Our customers confirm that by accelerating the
                      dynamic content, they are able to dramatically boost
                      business KPIs like top-line revenue, average order value
                      and most importantly conversion rates.
                    </p>

                    <p className={"text-base"}>
                      The most optimal way to leverage Zycada's programmable
                      intelligent edge platform is shown below. To start using
                      our eCommerce solution, simply point your site's DNS
                      records to Zycada CNAME.
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="row md:w-1/2">*/}
              {/*  <div className="text-center align-items-center">*/}
              {/*    <div className="text-center align-items-center">*/}
              {/*      <ZycadaModal></ZycadaModal>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="pt-6 text-center align-items-center"></div>*/}
              {/*</div>*/}
              <div className="pt-4 pb-4 text-center">
                <div className={"p-2 mx-auto"}>
                  <ZycadaArchitectureModal></ZycadaArchitectureModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <IndexFreeTrial />

    {/*<p>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    {/*</p>*/}
  </Layout>
)

export default SolutionsEcommerce
